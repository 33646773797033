<template>
  <detailed-view-container
    questionnaireTitle="Health Assessment Questionnaire"
    :series="selectedSeries"
    multiGraphs
    :graphOptions="graphOptions"
    @graphChanged="selectSeries"
  >
    <template v-slot:questionnnairePanel>
      <DASQuestionnairePanels
        :questionnaires="questionnaires"
        :questions="questionnaireQuestions"
      />
    </template>
  </detailed-view-container>
</template>

<script>
const components = {
  DASQuestionnairePanels: () =>
    import(
      "@/components/views/dashboard/members/id/partial/questionnaires/components/DASQuestionnairePanels.vue"
    ),
  DetailedViewContainer: () =>
    import(
      "@/components/views/dashboard/members/id/partial/questionnaires/DetailedViewContainer.vue"
    ),
};

export default {
  name: "DAS",

  data() {
    return {
      punctuationSeries: [
        {
          name: "",
          data: [
            null,
            null,
            null,
            null,
            2,
            7,
            3,
            1,
            10,
            5,
            6,
            null,
            null,
            null,
            null,
          ],
        },
      ],
      jointsSeries: [
        {
          name: this.$t("sore_joints"),
          data: [
            null,
            null,
            null,
            null,
            15,
            18,
            19,
            17,
            18,
            15,
            16,
            null,
            null,
            null,
            null,
          ],
        },
        {
          name: this.$t("swollen_joints"),
          data: [
            null,
            null,
            null,
            null,
            17,
            20,
            21,
            20,
            19,
            16,
            18,
            null,
            null,
            null,
            null,
          ],
        },
      ],
      questionnaires: [
        {
          date: "May 28, 2020",
          score: 20,
        },
        {
          date: "September 18, 2020",
          score: 10,
        },
      ],
      questionnaireQuestions: [
        {
          name: "How would you rate your physical well being over the past week?",
          value: 9,
        },
        {
          name: "How would you rate your physical well being over the past week?",
          value: 8,
        },
        {
          name: "How would you rate your physical well being over the past week?",
          value: 7,
        },
        {
          name: "How would you rate your physical well being over the past week?",
          value: 8,
        },
        {
          name: "How would you rate your physical well being over the past week?",
          value: 1,
        },
      ],
      graphOptions: [
        this.$t("punctuation").toUpperCase(),
        this.$t("joints").toUpperCase(),
      ],
      selectedSeries: null,
    };
  },

  components,

  mounted() {
    this.selectSeries();
  },

  methods: {
    selectSeries(e) {
      const selectedGraphIndex = e ? this.graphOptions.indexOf(e) : 0;
      this.selectedSeries =
        selectedGraphIndex == 0 ? this.punctuationSeries : this.jointsSeries;
    },
  },
};
</script>